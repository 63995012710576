* {
  margin: 0;
  padding: 0;
  font-weight: 300;
}

body {
  scroll-behavior: smooth;
}

p {
  font-size: 80%;
  color: #666666;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #7F7F7F;
  font-weight: 300;
  color: unset;
}

a,
a:hover {
  color: #333;
  text-decoration: none;
  font-weight: 300;
}

.container {
  padding: 0 20px;
}

.show {
  display: unset;
}

.hide {
  display: none !important;
}

.back-icon svg {
  width: 15px;
}

.container {
  padding-bottom: 50px;
}

.works-container {
  padding-bottom: 50px;
  position: relative;
}

.works-container .carousel-inner {
  height: 40vh;
  display: flex;
  align-items: center;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1rem;
  height: 1rem;
}

.page-container .carousel-caption {
  padding-bottom: 0;
}

.carousel-caption h3 {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  padding: 5px 10px;
  display: inline;
  color: #666666;
  font-size: 80%;
}

.works-container .carousel-indicators,
.page-container .carousel-indicators {
  bottom: -40px;
}

.works-container .carousel-item img {
  object-fit: contain;
  margin: 0 auto;
  max-height: 40vh;
}

.project-info h4 {
  font-size: 80%;
}

.about-page-info h4 {
  font-size: 100%;
}

/* ================================================================= */
/* === Mobile Styles === */
header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav {
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px 10px;
  text-align: center;
}

nav a {
  margin: 5px;
  color: #999;
}

header nav a.active {
  color: unset;
}

nav .separated-menu {
  margin-top: 5px;
  text-align: center;
}

img {
  max-width: 100%;
}

/* === Works Page === */
.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 15px 0;
}

.project {
  width: 150px;
  height: 250px;
  margin: 0 10px 40px 10px;
}

.project:hover,
.team-members .block:hover {
  cursor: pointer;
}

.project-img {
  width: 100%;
  height: 100%;
}

.project .project-info h4 {
  padding-top: 5px;
  line-height: 1.3;
  font-weight: light;
}

.project-specs {
  position: relative;
  left: 25px;
}

.project-specs p {
  margin-bottom: 1rem;
  font-size: 14px;
}

.project-info-text div {
  margin-top: -10px;
  padding-left: 25px;
  padding-right: 25px;
}

.info-box {
  margin-left: 25px;
}

.info-nav p {
  margin-bottom: unset;
  color: #999;
}

/* === People Page === */
.block {
  margin-bottom: 10px;
}

.core-value-cards .blocks,
.team-members.blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.core-value-cards .blocks {
  width: 100%;
  margin: 0 auto;
}

.core-value-cards .block,
.team-members.blocks .block {
  width: 15%;
  min-width: 220px;
}

.core-value-cards .block {
  width: 15%;
  min-width: 125px;
  margin: 0 10px;
}

.core-value-cards .blocks .values-img,
.team-members.blocks .block .teammate-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.core-value-cards .blocks .values-img {
  width: 120px;
  height: 120px;
}

.team-members.blocks .block .teammate-info h6,
.team-members.blocks .block .teammate-info p {
  text-align: center;
}

.address-block {
  margin-bottom: 15px;
}

.address-block p {
  margin-bottom: 0;
  line-height: 1.6;
}

.contact form {
  margin-top: 10px;
}

.contact form * {
  display: block;
}

.contact form label {
  margin-bottom: 5px;
}

.contact form input,
.contact form textarea,
.contact form button {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.contact form button {
  outline: none;
  border: none;
  background-color: #666666;
  color: #ffffff;
  padding: 5px 0;
  margin-top: 15px;
}

.social-links a {
  margin-right: 10px;
}

.employee-card {
  display: flex;
  flex-direction: column;
}

.employee-card .employee-bio {
  order: 2;
}

.employee-card .employee-bio .back-button {
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.privacy-container .section {
  padding-right: 20px;
}

.awards-container .press ol li {
  margin-bottom: 10px;
}

footer {
  position: absolute;
  bottom: -60px;
  left: 25%;
  right: 25%;
  background-color: rgba(255, 255, 255, 0.9);
}

/* ==== Awards Page === */
.awards-container,
.press-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  margin-bottom: 40px;
}

/* ================================================================= */
/* Tablet Started */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .projects-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    padding-right: 30px;
  }

  .project {
    width: 100%;
    min-width: 20vw;
    height: 450px;
  }

  .core-value-cards .blocks {
    width: 100%;
    min-width: 125px;
    flex-wrap: wrap;
  }

  .core-value-cards .block {
    min-width: 180px;
  }
}

/* Tablet Styles Ended */

/* ================================================================= */
/* PC Styles Started */
@media only screen and (min-width: 1024px) {
  header {
    width: 200px;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
  }

  header nav {
    padding-right: 15px;
    display: initial;
    overflow: hidden;
  }

  header nav a {
    display: block;
    text-align: right;
    color: #fff;
    margin: 5px 0;
    display: block;
  }

  header:hover nav a {
    color: #999;
    transition: all 0.5s linear;
  }

  header nav a.active {
    color: unset;
  }

  nav .separated-menu {
    margin-top: 30px;
  }

  .page-container {
    display: flex;
    padding-bottom: 30px;
  }

  .container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 0;
    max-width: unset;
  }

  .back-icon {
    width: unset;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 2rem;
    height: 2rem;
  }

  .carousel {
    width: 100%;
    height: 100vh;
  }

  .carousel-caption {
    background: unset;
  }

  .carousel-caption h3 {
    font-size: 180%;
    font-weight: 400;
    padding: 10px 20px;
  }

  .carousel:hover .carousel-caption {
    display: block;
  }

  .project-carousel {
    width: 100%;
  }

  .carousel-item.active {
    display: flex;
  }

  .carousel-item {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-item img {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    object-fit: cover;
  }

  /* === Home Page === */
  .home-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('https://images.unsplash.com/photo-1660262871193-67b6dee25ef0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
  }

  .home-container .hero-content,
  .carousel-caption {
    width: 40%;
    padding: 20px;
    position: absolute;
    bottom: 60px;
    left: 30%;
    text-align: center;
    color: #333;
    display: none;
    transition: display 1s;
  }

  .home-container .hero-content h3 {
    line-height: 1.8;
  }

  .page-container .carousel-indicators {
    bottom: 0;
  }

  /* === About Page === */
  .hero-container {
    height: 100vh;
  }

  .hero-container img {
    width: 100%;
    height: 100%;
  }

  .top-img {
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0;
  }

  .about-page-info {
    margin-top: 20px;
  }

  .about-page-info .blocks {
    width: 100%;
    padding: 10px;
    padding-left: 100px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }

  .about-page-info .block {
    padding: 0 20px;
    width: 90%;
  }

  .about-page-info .block h4 {
    font-size: 120%;
  }

  .about-page-info .block p {
    font-size: 100%;
  }

  /* === Works Page === */
  .projects-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    padding-right: 30px;
    padding-top: 30px;
    position: relative;
  }

  .project {
    width: 90%;
    height: 550px;
  }

  .project-img {
    max-width: 100%;
  }

  .project:hover .project-info h4 {
    visibility: visible;
  }

  .project .project-info h4 {
    font-size: 120%;
    padding: 5px 0 10px 0;
    visibility: hidden;
  }

  .works-container {
    height: 95vh;
  }

  .works-container .project-info-text p {
    font-size: 16px;
    padding: 10px;
  }

  .works-container .project-carousel .carousel-indicators {
    bottom: 0;
  }

  .works-container .project-carousel .carousel-inner {
    height: initial;
    display: initial;
    align-items: unset;
  }

  .works-container .project-carousel .carousel-item {
    width: 100%;
    height: auto;
  }

  .works-container .project-carousel .carousel-item img {
    object-fit: contain;
    width: 100vw;
    height: 100vh;
    max-height: unset;
    margin: unset;
  }

  .info-box {
    width: 12%;
    max-width: 160px;
    position: fixed;
    left: -39px;
    bottom: 50px;
    text-align: right;
  }

  .info-box .project-title {
    word-break: break-word;
  }

  .info-box p {
    font-size: 16px;
  }

  .info-nav {
    margin-top: 10px;
  }

  .info-nav p {
    cursor: pointer;
    text-align: right;
  }

  .info-nav p {
    line-height: 1.5;
    margin-bottom: 0;
  }

  .project-specs {
    position: absolute;
    bottom: 0;
  }

  .project-specs p {
    color: unset;
    font-size: 16px;
  }

  .project-info-text {
    padding-right: 20rem;
  }

  .project-info-text {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .project-info-text div {
    columns: 2;
    column-gap: 40px;
    padding-left: 0;
    width: 80%;
    margin-top: 25px;
    overflow-y: auto;
    margin-bottom: 22px;
    min-width: 600px;
  }

  .works-container .project-info-text div p {
    padding: 0;
  }

  /* === Studio Page === */
  .studio-container {
    padding: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
  }

  .culture-block {
    display: flex;
  }

  .culture-block .img-container {
    order: 2;
    width: 50%;
    padding: 10px;
    text-align: center;
  }

  .culture-block .img-container img {
    width: 100%;
    min-width: 400px;
    max-width: 600px;
  }

  .culture-block .info-container {
    width: 50%;
  }

  /* ==== People Page ==== */
  .people-container {
    padding: 20px;
    box-sizing: border-box;
  }

  .core-value-cards .blocks {
    width: 80%;
  }

  .core-value-cards .block {
    min-width: unset;
  }

  .people-container .employee-cards {
    margin-bottom: 20px;
  }

  .employee-cards .employee-card {
    margin-bottom: 20px;
  }

  .employee-card .employee-bio {
    width: 60%;
    position: absolute;
    bottom: 20%;
  }

  .employee-card .employee-bio .back-button {
    font-weight: bold;
    cursor: pointer;
    position: relative;
    left: -70px;
    top: 32px;
  }

  .employee-card .employee-bio .info p {
    line-height: 1.8;
  }

  .employee-card .employee-info {
    width: 20%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 40px;
    box-sizing: border-box;
  }

  .employee-card .employee-info .edu p {
    line-height: 1.8;
  }

  .employee-card .employee-info img {
    width: 100%;
  }

  /* === Contacts Page === */
  .contact-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
  }

  .contact {
    width: 25%;
    min-width: 250px;
    padding: 10px;
    box-sizing: border-box;
  }

  .contact form input,
  .contact form textarea,
  .contact form button {
    width: 80%;
  }

  .social-links a {
    margin: 0 5px;
  }
}

/* PC Styles Ended */